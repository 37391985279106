import { contentDefaults, styleDefaults } from "./PageDefaults";
import validatePageSchema from "./ValidatePageSchema";
import isEqual from "lodash.isequal";

export default function usePageData() {
  const pageStyle = reactive<PageStyle>(styleDefaults);
  const pageContent = reactive<PageContent>(contentDefaults);
  const pageLoading = ref(true);
  const isPageSaving = ref(false);
  const currentOrganization = ref<Organization | null>(null);

  const savePageTimeout = ref<NodeJS.Timeout | number>(0);

  const isStateLoaded = ref(false);

  const debounceSave = (
    newData: PageContent | PageStyle,
    oldData: PageContent | PageStyle
  ): void => {
    if (!isStateLoaded.value) {
      return;
    }

    if (shouldSave(newData, oldData)) {
      isPageSaving.value = true;
      clearTimeout(savePageTimeout.value);
      savePageTimeout.value = setTimeout(() => {
        savePage({
          style: pageStyle,
          content: pageContent,
        });
      }, 500);
    }
  };

  const shouldSave = (
    newData: PageContent | PageStyle,
    oldData: PageContent | PageStyle
  ): boolean => {
    return !isEqual(newData, oldData);
  };

  watch(
    () => ({
      ...pageContent,
      sections: {
        ...pageContent.sections,
        cards: pageContent.sections.cards.map((card) => ({ ...card })),
        videoGallery: pageContent.sections.videoGallery.map((video) => ({
          ...video,
        })),
      },
    }),
    (newValue, oldValue) => {
      debounceSave(unref(newValue), unref(oldValue));
    },
    { deep: true }
  );
  watch(
    () => ({ ...pageStyle }),
    (newValue, oldValue) => {
      debounceSave(unref(newValue), unref(oldValue));
    },
    { deep: true }
  );

  const loadOrganizationFromSession = async () => {
    const staffToken = useStaffStore().staffToken;

    if (staffToken) {
      const organization = await $circleFetch<Organization>(
        "admin/organization",
        {
          method: "GET",
          headers: {
            Authorization: staffToken,
          },
        }
      );

      currentOrganization.value = organization;
    }
  };

  const loadFromSession = async () => {
    isStateLoaded.value = false;
    pageLoading.value = true;
    const page = await $circleFetch<Page>("/organization/page");

    /* If there's no content, then initialize with defaults */
    if (!page || !page.style || !page.content) {
      const staffStore = useStaffStore();

      const defaultPageData = {
        style: styleDefaults,
        content: {
          ...contentDefaults,
          title: staffStore.info?.organization.name ?? "",
        },
      };
      await savePage(defaultPageData);

      Object.assign(pageStyle, defaultPageData.style);
      Object.assign(pageContent, defaultPageData.content);

      nextTick(() => {
        pageLoading.value = false;
        isStateLoaded.value = true;
      });
      return;
    }

    const validadedPageSchema = validatePageSchema(page);

    Object.assign(pageStyle, validadedPageSchema.style);
    Object.assign(pageContent, validadedPageSchema.content);

    nextTick(() => {
      pageLoading.value = false;
      isStateLoaded.value = true;
    });
  };

  const loadFromUrl = async (url: string) => {
    try {
      const res = await $circleFetch<HomepageResponse>(
        `/organization/getByUrl/${url}`
      );

      currentOrganization.value = res.organization;

      const validadedPageSchema = validatePageSchema(res.page);

      Object.assign(pageStyle, validadedPageSchema.style);
      Object.assign(pageContent, validadedPageSchema.content);

      pageLoading.value = false;

      return true;
    } catch (e) {
      console.error("ERROR", e);
      return false;
    }
  };

  const savePage = async (page: Page) => {
    isPageSaving.value = true;

    await $circleFetch("/organization/page", {
      method: "POST",
      body: {
        content: page,
      },
    });

    isPageSaving.value = false;
  };

  function savePageStyle(
    brandColors: {
      background: string;
      textColor: string;
      copyColor: string;
      shadowColor: string;
    },
    selectedTheme: "light" | "dark",
    sphereGradientColors: {
      firstColor: string;
      lastColor: string;
    }
  ) {
    pageStyle.brandColors = brandColors;
    pageStyle.selectedTheme = selectedTheme;
    pageStyle.sphereGradientColors = sphereGradientColors;
  }

  function saveLogoImage(imageUrl: string): void {
    pageContent.logo = imageUrl;
  }

  function saveHeroVideo(video: {
    videoUrl: string;
    coverUrl: string;
    iconUrl: string;
  }): void {
    pageContent.heroVideo = video;
  }

  function addFaqQuestion(): void {
    const newQuestion = {
      title: "New Question",
      answer: "New Answer",
    };
    pageContent.sections.faqQuestions.push(newQuestion);
    nextTick(() =>
      savePage({ style: { ...pageStyle }, content: { ...pageContent } })
    );
  }

  function saveFaqQuestion(
    question: {
      title: string;
      answer: string;
    },
    index: number
  ): void {
    pageContent.sections.faqQuestions[index] = question;
    nextTick(() =>
      savePage({ style: { ...pageStyle }, content: { ...pageContent } })
    );
  }

  function deleteFaqQuestion(index: number): void {
    pageContent.sections.faqQuestions.splice(index, 1);
    nextTick(() =>
      savePage({ style: { ...pageStyle }, content: { ...pageContent } })
    );
  }

  const faqFunctions = {
    create: addFaqQuestion,
    save: saveFaqQuestion,
    delete: deleteFaqQuestion,
  };

  function addVideoGallery(
    newVideo: (typeof pageContent.sections.videoGallery)[number],
    videoPosition?: "start" | "end"
  ): void {
    if (pageContent.sections.videoGallery.length >= 10) return;

    if (videoPosition === "start") {
      pageContent.sections.videoGallery = [
        newVideo,
        ...pageContent.sections.videoGallery,
      ];
    } else {
      pageContent.sections.videoGallery.push(newVideo);
    }

    nextTick(() =>
      savePage({ style: { ...pageStyle }, content: { ...pageContent } })
    );
    return;
  }

  function saveVideoGallery(
    video: (typeof pageContent.sections.videoGallery)[number],
    index: number
  ): void {
    pageContent.sections.videoGallery[index] = video;
    nextTick(() =>
      savePage({ style: { ...pageStyle }, content: { ...pageContent } })
    );
  }

  function deleteVideoGallery(index: number): void {
    pageContent.sections.videoGallery.splice(index, 1);
    nextTick(() =>
      savePage({ style: { ...pageStyle }, content: { ...pageContent } })
    );
    return;
  }

  const videoGalleryFunctions = {
    create: addVideoGallery,
    save: saveVideoGallery,
    delete: deleteVideoGallery,
  };

  function saveQuoteSection(quoteCard: {
    quoter: {
      name: string;
      photo: string;
      title: string;
    };
    quote: string;
  }): void {
    pageContent.sections.quoteCard = quoteCard;

    return;
  }

  function saveCard(
    card: {
      title: string;
      text: string;
      coverUrl: string;
    },
    index: number
  ): void {
    pageContent.sections.cards[index] = card;

    nextTick(() =>
      savePage({ style: { ...pageStyle }, content: { ...pageContent } })
    );
  }

  function saveGradientColors(newGradientColors: {
    firstColor: string;
    secondColor: string;
  }): void {
    pageStyle.gradientColors = newGradientColors;
  }

  function updateAboutSection(text: string): void {
    pageContent.sections.about = text;
  }

  function updateTitle(title: string): void {
    pageContent.title = title;
  }

  function updateSectionTitle(
    section: keyof PageContent["sections"]["titles"],
    title: string
  ): void {
    debounceSave(
      {
        ...pageContent,
        sections: {
          ...pageContent.sections,
          titles: {
            ...pageContent.sections.titles,
            [section]: title,
          },
        },
      },
      pageContent
    );
    nextTick(() => {
      pageContent.sections.titles[section] = title;
    });
  }

  function updateOgTags(newOgTags: {
    image: string;
    description: string;
  }): void {
    pageStyle.ogTags = newOgTags;
    nextTick(() =>
      savePage({ style: { ...pageStyle }, content: { ...pageContent } })
    );
  }

  function addBrandLogo(url: string) {
    pageContent.sections.brandLogos.push(url);
    nextTick(() =>
      savePage({ style: { ...pageStyle }, content: { ...pageContent } })
    );
  }

  function removeBrandLogo(index: number) {
    pageContent.sections.brandLogos.splice(index, 1);
    nextTick(() =>
      savePage({ style: { ...pageStyle }, content: { ...pageContent } })
    );
  }

  return {
    currentOrganization,
    isPageSaving,
    pageLoading,
    pageStyle,
    pageContent,
    loadFromSession,
    loadFromUrl,
    loadOrganizationFromSession,
    savePage,
    saveCard,
    saveQuoteSection,
    faqFunctions,
    videoGalleryFunctions,
    savePageStyle,
    saveLogoImage,
    saveHeroVideo,
    saveGradientColors,
    updateAboutSection,
    updateTitle,
    updateOgTags,
    updateSectionTitle,
    addBrandLogo,
    removeBrandLogo,
  };
}
